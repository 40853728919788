import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  oneDay: "N",
  lastOpenHour: "",
  lastOpenDay: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    dayModal: (state, action) => {
      if (action.payload.oneDay !== undefined) {
        state.oneDay = action.payload.oneDay;
      }
      if (action.payload.lastOpenHour !== undefined) {
        state.lastOpenHour = action.payload.lastOpenHour;
      }
      if (action.payload.lastOpenDay !== undefined) {
        state.lastOpenDay = action.payload.lastOpenDay;
      }
    },
    clearModal: () => {
      return initialState;
    },
  },
});

export const { dayModal, clearModal } = modalSlice.actions;
export default modalSlice.reducer;
