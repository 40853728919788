import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import hanbokboy from "../../Asset/newyear/hanbokboy.webp";
import hanbokgirl from "../../Asset/newyear/hanbokgirl.webp";
import message from "../../Asset/newyear/message_pc.webp";
import tile_pc from "../../Asset/newyear/bg_tile_pc.webp";

function FullModal(props) {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="fixed z-30 w-[1200px] h-[600px] max-w-[90vw] max-h-[90vh] p-4 bg-[#fefeea] rounded top-[200px] left-1/2 pcLayer transition-all duration-500 shadow-lg bg-opacity-80"
        onClick={() => props.closeIt()}
      >
        <img
          src={message}
          className="max-w-full"
          alt="새해 복 많이 받으세요 다사다난했던 2024년을 마무리 하고, 2025년 새해에는 푸른 뱀의 기운을 받아, 멈추지 않는 채용서비스와 함께, 원하시는 바를 모두 이룰 수 있길 바랍니다"
        />
      </div>
      <div
        className="fixed z-[999]  min-w-[100px] w-fit bottom-5 left-10 lg:block hidden"
        onClick={() => props.closeIt()}
      >
        <img src={hanbokboy} className="w-auto max-h-[400px]" alt="한복소년" />
      </div>
      <div
        className="fixed z-[999] min-w-[100px] w-fit bottom-5 right-10 lg:block hidden"
        onClick={() => props.closeIt()}
      >
        <img src={hanbokgirl} className="w-auto max-h-[400px]" alt="한복소녀" />
      </div>
      <div
        className="fixed z-30 w-screen h-[200px] top-0 left-0"
        style={{
          backgroundImage: `url(${tile_pc})`,
          backgroundRepeat: "repeat-x",
        }}
        onClick={() => props.closeIt()}
      />
      <div
        className="fixed z-20 bg-black bg-opacity-20 top-0 left-0 w-screen h-screen overflow-hidden"
        onClick={() => props.closeIt()}
      ></div>
    </>
  );
}

export default FullModal;
