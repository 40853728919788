import React, { useState, useEffect } from "react";
import { collection, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
//import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";

import Mbti from "./Mbti";

import user from "../Asset/user2.png";
import queryString from "query-string";

function RenewalResult() {
  let navi = useNavigate();

  const [success, setSuccess] = useState(false);

  const [surveyData, setSurveyData] = useState({
    profile: {},
    surveys: { s01: [], s02: [], s03: [] },
  });

  const [answerEx, setAnswerEx] = useState([]);
  const [moreAnswer, setMoreAnswer] = useState([]);
  const [blood, setBlood] = useState("");

  const thisLocation = useLocation();
  const parsed = queryString.parse(thisLocation.search);
  const no = parsed.no || false;
  const admin = parsed.admin || "";

  const [isLoading, setIsLoading] = useState(false);
  const [imgIsLoading, setImgIsLoading] = useState(false);

  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    if (!no) {
      navi("/error");
    }
    fetchSurveyData(no);
    setIsAdmin(admin !== "no");
    const titleElement = document.getElementsByTagName("title")[0];
    titleElement.innerHTML = `KoTI 설문지`;
    // eslint-disable-next-line
  }, []);
  /* 백업
  const fetchSurveyData = async surveyId => {
    setIsLoading(false);

    const resultRef = doc(collection(db, "renewalSurvey"), `${surveyId}`);
    const surveySnapshot = await getDoc(resultRef);

    if (surveySnapshot.exists()) {
      const { profile, answers } = surveySnapshot.data();
      const surveys = { s01: [], s02: [], s03: [] };

      const basicRef = collection(db, "newSurvey");

      for (const category in answers) {
        for (const questionId in answers[category]) {
          const questionRef = doc(basicRef, questionId);
          const questionSnapshot = await getDoc(questionRef);

          if (questionSnapshot.exists()) {
            surveys[category].push({
              id: questionId,
              question: questionSnapshot.data().question,
              answer: answers[category][questionId],
            });
          }
        }
      }
      setSurveyData({ profile, surveys });
      setIsLoading(true);
    } else {
      alert("데이터를 불러오는 중 오류가 발생했습니다.");
    }
  };
  */

  const fetchSurveyData = async surveyId => {
    setIsLoading(false);

    const resultRef = doc(collection(db, "renewalSurvey"), `${surveyId}`);
    const surveySnapshot = await getDoc(resultRef);

    if (surveySnapshot.exists()) {
      const { profile, answers, answerEx } = surveySnapshot.data();
      const surveys = { s01: [], s02: [], s03: [] };

      const basicRef = collection(db, "newSurvey");

      if (answerEx && answerEx.length > 0) {
        setAnswerEx(answerEx);
      } else {
        setAnswerEx([]);
      }

      for (const category in answers) {
        // questionId를 배열로 추출 후 정렬
        const sortedQuestionIds = Object.keys(answers[category]).sort(
          (a, b) => {
            // 숫자를 기준으로 정렬
            const numA = parseInt(a.replace(/\D/g, ""), 10);
            const numB = parseInt(b.replace(/\D/g, ""), 10);
            return numA - numB;
          }
        );
        for (const questionId of sortedQuestionIds) {
          const questionRef = doc(basicRef, questionId);
          const questionSnapshot = await getDoc(questionRef);
          if (questionSnapshot.exists()) {
            if (questionId !== "survey01") {
              surveys[category].push({
                id: questionId,
                question: questionSnapshot.data().question,
                answer: answers[category][questionId],
              });
            } else {
              setBlood(answers[category][questionId]);
            }
          }
        }
      }
      const svtxt = [
        "survey02",
        "survey103",
        "survey104",
        "survey105",
        "survey106",
        "survey202",
        "survey205",
        "survey207",
      ];

      const moreAnswer = [];
      for (const sv of svtxt) {
        const answer = await getSurveyAnswerByIdFromAll(surveys, sv);
        moreAnswer.push(answer);
      }
      setMoreAnswer(moreAnswer);
      setSurveyData({ profile, surveys });
      setIsLoading(true);
    } else {
      alert("데이터를 불러오는 중 오류가 발생했습니다.");
    }
  };

  function getSurveyAnswerByIdFromAll(surveys, targetId) {
    for (const category in surveys) {
      const result = surveys[category].find(item => item.id === targetId);
      if (result) return result.answer; // answer만 반환
    }
    return null; // 찾지 못한 경우 null 반환
  }

  const hasFinalConsonant = txt => {
    const text = txt.charAt(txt.length - 1);
    const code = text.charCodeAt(0);

    // 한글 유니코드 범위에 있는지 확인
    if (code < 0xac00 || code > 0xd7a3) {
      return false; // 한글이 아니면 받침 없음
    }

    // 종성 계산
    const jongseongIndex = (code - 0xac00) % 28;

    return jongseongIndex === 0; // 0이면 받침 없음, 아니면 받침 있음
  };

  const onSaveToOneImg = async () => {
    window.scrollTo(0, 0);
    await setSuccess(false);
    let now = dayjs();
    let capture = document.querySelector("#capture");
    let captureTime = now.format("YYYYMMDDHHmm");

    try {
      const blob = await domtoimage.toBlob(capture, {
        style: {
          transform: "scale(1)",
          transformOrigin: "top left",
        },
        width: capture.offsetWidth,
        height: capture.offsetHeight,
      });

      await navigator.clipboard.write([
        new ClipboardItem({
          "image/png": blob,
        }),
      ]);

      saveAs(
        URL.createObjectURL(blob),
        `${surveyData.profile.name}_${captureTime}.png`
      );

      setSuccess(true);
    } catch (error) {
      console.error("Failed to save or copy image:", error);
    }
  };

  const onSaveToImg = async () => {
    const captureIds = ["#capture1", "#capture2", "#capture3", "#capture4"];

    await setSuccess(false);
    let now = dayjs();
    let captureTime = now.format("YYYYMMDDHHmm");

    for (const [index, captureId] of captureIds.entries()) {
      let capture = document.querySelector(captureId);
      if (!capture) {
        console.error(`Element with ID ${captureId} not found.`);
        continue;
      }

      try {
        const blob = await domtoimage.toBlob(capture, {
          style: {
            transform: "scale(1)",
            transformOrigin: "top left",
          },
          width: capture.offsetWidth,
          height: capture.offsetHeight,
        });

        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": blob,
          }),
        ]);

        saveAs(
          URL.createObjectURL(blob),
          `${surveyData.profile.name}_${index + 1}_${captureTime}.png`
        );

        if (index === captureIds.length - 1) {
          setSuccess(true);
        }
      } catch (error) {
        console.error(`Failed to save or copy image ${index + 1}:`, error);
      }
    }
  };

  const saveAs = (uri, filename) => {
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  /* html2canvas 기준
  const onSaveToOneImg = async () => {
    window.scrollTo(0, 0);
    await setIsCapture(true);
    await setSuccess(false);
    let now = dayjs();
    let capture = document.querySelector("#capture");
    let captureTime = now.format("YYYYMMDDHHmm");

    await html2canvas(capture, {
      allowTaint: true,
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
      scale: window.devicePixelRatio, // CSS 스타일 적용을 위해 scale 옵션 추가
    }).then(async canvas => {
      try {
        // Canvas를 Blob으로 변환
        const blob = await new Promise(resolve =>
          canvas.toBlob(resolve, "image/png")
        );

        // Clipboard API를 사용하여 이미지를 클립보드에 복사
        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": blob,
          }),
        ]);
        console.log("Image copied to clipboard successfully");
      } catch (error) {
        console.error("Failed to copy image to clipboard:", error);
      }

      await saveAs(
        canvas.toDataURL("image/png"),
        surveyData.profile.name + "_" + captureTime + ".png"
      );

      setSuccess(true);
    });
    setIsCapture(false);
  };

  const onSaveToImg = async () => {
    const captureIds = ["#capture1", "#capture2", "#capture3", "#capture4"];
    await setIsCapture(true);
    await setSuccess(false);
    let now = dayjs();
    let captureTime = now.format("YYYYMMDDHHmm");

    for (const [index, captureId] of captureIds.entries()) {
      let capture = document.querySelector(captureId);
      if (!capture) {
        console.error(`Element with ID ${captureId} not found.`);
        continue;
      }

      await html2canvas(capture, {
        allowTaint: true,
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        scale: window.devicePixelRatio, // CSS 스타일 적용을 위해 scale 옵션 추가
      }).then(async canvas => {
        try {
          // Canvas를 Blob으로 변환
          const blob = await new Promise(resolve =>
            canvas.toBlob(resolve, "image/png")
          );

          // Clipboard API를 사용하여 이미지를 클립보드에 복사
          await navigator.clipboard.write([
            new ClipboardItem({
              "image/png": blob,
            }),
          ]);
          console.log(`Image ${index + 1} copied to clipboard successfully`);
        } catch (error) {
          console.error(
            `Failed to copy image ${index + 1} to clipboard:`,
            error
          );
        }

        await saveAs(
          canvas.toDataURL("image/png"),
          `${surveyData.profile.name}_${index + 1}_${captureTime}.png`
        );

        if (index === captureIds.length - 1) {
          setSuccess(true);
        }
      });
    }

    setIsCapture(false);
  };

  const saveAs = (uri, filename) => {
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;
      //Firefox requires the link to be in the body
      document.body.appendChild(link);
      //simulate click
      link.click();
      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };
*/
  const handleImgError = e => {
    e.target.src = user;
  };

  return (
    <>
      <div
        onContextMenu={e => {
          if (!isAdmin) {
            e.preventDefault();
            alert("복사 및 유출 방지를 위해 우클릭을 금지하였습니다.");
          }
        }}
      >
        {!isLoading ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-white bg-opacity-80 z-50">
            <div className="text-center mt-96">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className={
                !imgIsLoading
                  ? "fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-white bg-opacity-80 z-50"
                  : "hidden opacity-0 w-0 h-0"
              }
            >
              <div className="text-center mt-96">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {isAdmin ? (
              <div className="fixed top-0 left-0 right-0 p-2 bg-white drop-shadow-lg mb-3 text-center z-40">
                <div className="container mx-auto  flex justify-center gap-5">
                  <button
                    className="bg-teal-500 hover:bg-teal-700 py-3 px-10 font-medium m-auto text-white rounded-full"
                    onClick={onSaveToImg}
                  >
                    분할 이미지로 저장
                  </button>
                  <button
                    className="bg-sky-500 hover:bg-sky-700 py-3 px-10 font-medium m-auto text-white rounded-full"
                    onClick={onSaveToOneImg}
                  >
                    통합 이미지로 저장
                  </button>
                  {success && (
                    <div className="text-center text-blue-500 flex flex-col justify-center font-bold">
                      저장완료, 원하는 곳에 붙여넣기 하세요
                    </div>
                  )}

                  <button
                    className="bg-blue-500 hover:bg-blue-700 py-3 px-10 font-medium m-auto text-white rounded-full"
                    onClick={e => navi("/newsvlist")}
                  >
                    설문지 리스트로
                  </button>
                </div>
              </div>
            ) : null}
            <div
              className={`flex flex-col container mx-auto  ${
                isAdmin ? "mt-20" : "mt-2 noDrag"
              }`}
            >
              <div className="w-11/12 md:w-4/5 mx-auto rounded mb-3 max-w-2xl">
                <div
                  id="capture"
                  className="flex flex-col justify-start gap-y-10 bg-transparent"
                >
                  <div
                    className="bg-[#B3E5FC] drop-shadow w-full p-2"
                    id="capture1"
                  >
                    <div className="bg-white text-lg box-border border-2 drop-shadow p-1">
                      <h2 className="px-2 py-4 text-2xl font-bold bg-[#B3E5FC]">
                        {surveyData.profile.name}님의 프로필
                      </h2>
                      <ul className="flex flex-col px-2 pt-4">
                        <li>
                          <div className="flex justify-start gap-x-2">
                            <div className="p-2 bg-white flex flex-col justify-center">
                              <div className="w-48 h-48 border">
                                {surveyData.profile.photo === "noPhoto" ||
                                surveyData.profile.photo === "" ? (
                                  <img
                                    src={user}
                                    alt="사진이 없습니다"
                                    className="max-h-48"
                                    onLoad={e => setImgIsLoading(true)}
                                  />
                                ) : (
                                  <img
                                    src={surveyData.profile.photo}
                                    alt="프로필사진"
                                    className="max-h-48"
                                    onLoad={e => setImgIsLoading(true)}
                                    onError={e => handleImgError(e)}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="grid grid-cols-1 w-full py-2">
                              <div className="p-2 bg-gray-200 font-medium">
                                지원자명
                              </div>
                              <div className="p-2 bg-white">
                                {surveyData.profile.name}
                              </div>
                              <div
                                className={`grid ${
                                  surveyData.profile.gender !== ""
                                    ? "grid-cols-2"
                                    : "grid-cols-1"
                                }`}
                              >
                                {surveyData.profile.gender &&
                                  surveyData.profile.gender !== "" && (
                                    <div>
                                      <div className="p-2 bg-gray-200 font-medium">
                                        성별
                                      </div>
                                      <div className="p-2 bg-white">
                                        {surveyData.profile.gender === "female"
                                          ? "여성"
                                          : "남성"}
                                      </div>
                                    </div>
                                  )}
                                <div>
                                  <div className="p-2 bg-gray-200 font-medium">
                                    혈액형
                                  </div>
                                  <div className="p-2 bg-white">{blood}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="bg-white p-2">
                            {surveyData.profile.mbti !== "잘 모르겠어요" ? (
                              <Mbti mbti={surveyData.profile.mbti} />
                            ) : (
                              surveyData.profile.mbti
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="p-2 bg-gray-200 font-bold">
                            <span className="text-red-600">코티 Pick!</span>{" "}
                            {surveyData.profile.name}님의 채용 Point!
                          </div>
                          {answerEx.length > 0 && (
                            <div className="p-2 py-4 bg-white">
                              <div className="grid grid-cols-1 gap-4">
                                {answerEx.map((answer, idx) => (
                                  <div
                                    className="bg-gray-100 text-xl py-2 px-4 rounded-bl-xl rounded-tr-xl border"
                                    key={idx}
                                  >
                                    {answer}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </li>
                        {moreAnswer.length > 0 && (
                          <li>
                            <div className="p-2 bg-gray-200 font-bold hidden">
                              {surveyData.profile.name}님의 채용 Point
                            </div>
                            <div className="p-2 bg-white">
                              <div className="flex flex-row justify-start gap-4 flex-wrap">
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  {moreAnswer[0] === "체력" ? (
                                    <span>
                                      저는{" "}
                                      <span className="font-bold">
                                        {moreAnswer[0]}
                                      </span>
                                      이 강합니다!
                                    </span>
                                  ) : moreAnswer[0] === "목소리" ? (
                                    <span>
                                      저는{" "}
                                      <span className="font-bold">
                                        {moreAnswer[0]}
                                      </span>
                                      에 자신이 있습니다
                                    </span>
                                  ) : moreAnswer[0] === "친화력" ? (
                                    <span>
                                      저는{" "}
                                      <span className="font-bold">
                                        {moreAnswer[0]}
                                      </span>
                                      이 좋습니다!
                                    </span>
                                  ) : moreAnswer[0] === "열정" ? (
                                    <span>
                                      저는{" "}
                                      <span className="font-bold">
                                        {moreAnswer[0]}
                                      </span>
                                      이 남다릅니다!
                                    </span>
                                  ) : null}
                                </div>
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  <span className="font-bold">
                                    {moreAnswer[1]}
                                  </span>
                                  와 일하고 싶습니다
                                </div>
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  저는 채용공고의{" "}
                                  <span className="font-bold">
                                    {moreAnswer[2]}
                                  </span>
                                  {hasFinalConsonant(moreAnswer[2])
                                    ? "가"
                                    : "이"}{" "}
                                  좋았습니다
                                </div>
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  저는 인생에서{" "}
                                  <span className="font-bold">
                                    {moreAnswer[3]}
                                  </span>
                                  {hasFinalConsonant(moreAnswer[3])
                                    ? "를"
                                    : "을"}{" "}
                                  가장 중요하게 생각합니다
                                </div>
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  저는 회사를 선택할 때{" "}
                                  <span className="font-bold">
                                    {moreAnswer[4]}
                                  </span>
                                  {hasFinalConsonant(moreAnswer[4])
                                    ? "를"
                                    : "을"}{" "}
                                  중요하게 생각합니다
                                </div>
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  저는{" "}
                                  <span className="font-bold">
                                    {moreAnswer[7]}
                                  </span>{" "}
                                  입니다
                                </div>
                                <div className="bg-gray-100 text-base p-2 px-4 border rounded-full w-fit h-fit whitespace-nowrap break-keep">
                                  저는{" "}
                                  <span className="font-bold">
                                    {moreAnswer[6].join(", ")}
                                  </span>{" "}
                                  업무 경험이 있습니다
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="bg-violet-100 drop-shadow w-full p-2"
                    id="capture2"
                  >
                    <div className="bg-white text-lg box-border drop-shadow p-1">
                      <h2 className="px-2 py-4 text-2xl font-bold bg-violet-100">
                        {surveyData.profile.name}님의 성향
                      </h2>
                      <ul id="surveyA" className="flex flex-col px-2 pt-4">
                        {surveyData.surveys.s01 &&
                          surveyData.surveys.s01.map((sur, idx) => (
                            <li id={sur.id} key={idx}>
                              <div className="py-2 px-6 bg-gradient-to-r from-violet-50 font-medium rounded-full">
                                <span className="text-red-600 font-bold">
                                  Q.
                                </span>{" "}
                                {sur.question}
                              </div>
                              <div className="py-2 px-6 bg-white mb-5">
                                {Array.isArray(sur.answer)
                                  ? sur.answer.join(", ")
                                  : sur.answer}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="bg-emerald-100 drop-shadow w-full p-2"
                    id="capture3"
                  >
                    <div className="bg-white text-lg box-border border-2 drop-shadow p-1">
                      <h2 className="px-2 py-4 text-2xl font-bold bg-emerald-100">
                        {surveyData.profile.name}님이 선호하는 회사
                      </h2>
                      <ul id="surveyB" className="flex flex-col px-2 pt-4">
                        {surveyData.surveys.s02 &&
                          surveyData.surveys.s02.map((sur, idx) => (
                            <li id={sur.id} key={idx}>
                              <div className="py-2 px-6 bg-gradient-to-r from-emerald-50 font-medium rounded-full">
                                <span className="text-red-600 font-bold">
                                  Q.
                                </span>{" "}
                                {sur.question}
                              </div>
                              <div className="py-2 px-6 bg-white mb-5">
                                {Array.isArray(sur.answer)
                                  ? sur.answer.join(", ")
                                  : sur.answer}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="bg-orange-100 drop-shadow w-full p-2"
                    id="capture4"
                  >
                    <div className="bg-white text-lg box-border border-2 drop-shadow p-1">
                      <h2 className="px-2 py-4 text-2xl font-bold bg-orange-100">
                        {surveyData.profile.name}님의 업무 이력
                      </h2>
                      <ul id="surveyC" className="flex flex-col px-2 pt-4">
                        {surveyData.surveys.s03 &&
                          surveyData.surveys.s03.map((sur, idx) => (
                            <li id={sur.id} key={idx}>
                              <div className="py-2 px-6 bg-gradient-to-r from-orange-50 font-medium rounded-full">
                                <span className="text-red-600 font-bold">
                                  Q.
                                </span>{" "}
                                {sur.question}
                              </div>
                              <div className="py-2 px-6 bg-white mb-5">
                                {Array.isArray(sur.answer)
                                  ? sur.answer.join(", ")
                                  : sur.answer}
                              </div>
                            </li>
                          ))}
                      </ul>

                      <div id="warning" className="mt-3 text-center text-sm">
                        <div className="p-2 bg-white">
                          본 자료는{" "}
                          <span className="font-medium">
                            지원자의 소중한 개인정보가 담겨있습니다.
                          </span>{" "}
                          면접시에만 활용해 주세요.{" "}
                          <br className="hidden lg:block" />
                          개인정보를 임의로 보관할 경우 개인정보보호법에 따라
                          불이익을 받을 수 있습니다.{" "}
                          <br className="hidden lg:block" />본 자료양식은{" "}
                          <span className="font-medium text-red-500">
                            코리아티엠
                          </span>
                          의 고유재산이므로 수정 및 재배포를 금지합니다.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default RenewalResult;
