import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dayModal } from "../../Reducer/modalSlice";

import topBar from "../../Asset/newyear/topbar.webp";
import FullModal from "./FullModal";
import FullModalMobile from "./Mobile/FullModalMobile";

function TopLayer() {
  const thisLocation = useLocation();
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modal);
  const [fullModal, setFullModal] = useState(false);

  useEffect(() => {
    const now = new Date();
    const today = dayjs(now).format("DD");
    if (
      thisLocation.pathname === "/home" ||
      thisLocation.pathname === "/mobile"
    ) {
      if (!modalState.lastOpenDay || modalState.lastOpenDay !== today) {
        setFullModal(true);
        dispatch(dayModal({ lastOpenDay: today }));
      } else {
        setFullModal(false);
      }
    } else {
      setFullModal(false);
    }

    //eslint-disable-next-line
  }, [thisLocation]);

  const closeIt = () => {
    setFullModal(false);
  };
  return (
    <>
      <div
        className="w-full py-4 bg-[#fefeea] text-2xl justify-center gap-x-2 hover:cursor-auto border-b hidden"
        onClick={() => setFullModal(true)}
      >
        <img
          src={topBar}
          alt="근하신년"
          className="w-auto max-w-[90%] mx-auto max-h-[64px]"
        />
      </div>
      {fullModal && (
        <>
          <div className="lg:block hidden h-fit overflow-hidden">
            <FullModal closeIt={closeIt} />
          </div>
          <div className="lg:hidden overflow-hidden">
            <FullModalMobile closeIt={closeIt} />
          </div>
        </>
      )}
    </>
  );
}

export default TopLayer;
