import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Agree from "./Agree";
import InputName from "./InputName";
import InputMbti from "./InputMbti";
import InputPhoto from "./InputPhoto";
import InputPhone from "./InputPhone";
import Error from "./Error";

import dayjs from "dayjs";

function BasicSurveyRenew() {
  const navi = useNavigate();
  const thisLocation = useLocation();
  const parsed = queryString.parse(thisLocation.search);
  const no = parsed.no || false;
  const aliasNum = parsed.alias || false;
  const gender = parsed.gender || "";
  const [surveyA, setSurveyA] = useState([]);
  const [surveyB, setSurveyB] = useState([]);
  const [surveyC, setSurveyC] = useState([]);
  const [answers, setAnswers] = useState({ s01: {}, s02: {}, s03: {} });

  const [surveyEx, setSurveyEx] = useState(null);
  const [answerEx, setAnswerEx] = useState([]);

  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [photoTask, setPhotoTask] = useState("");
  const [phone, setPhone] = useState("");
  const [mbti, setMbti] = useState("");
  const [profile, setProfile] = useState({});
  const [category, setCategory] = useState(0);
  const [agree, setAgree] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getResult = async () => {
    const querySnapshot = await getDocs(collection(db, "newSurvey"));
    const listA = [];
    const listB = [];
    const listC = [];
    let listEx;

    querySnapshot.forEach(doc => {
      const data = doc.data();
      if (!data.noUse) {
        if (data.category === "s01") listA.push(data);
        else if (data.category === "s02") listB.push(data);
        else if (data.category === "s03") listC.push(data);
        else if (data.category === "s99") listEx = data;
      }
    });
    console.log(listEx);
    setSurveyA(listA);
    setSurveyB(listB);
    setSurveyC(listC);
    setSurveyEx(listEx);

    setTimeout(() => setLoading(true), 1000);
  };

  useEffect(() => {
    getResult();
    if (!no) setError(true);
    document.title = "KoTI 설문지";
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let body = profile;
    body.photo = photo;
    body.photoTask = photoTask;
    setProfile(body);
    //eslint-disable-next-line
  }, [photo, photoTask]);

  const handleAnswer = (value, questionId, categoryKey) => {
    setAnswers(prev => ({
      ...prev,
      [categoryKey]: {
        ...prev[categoryKey],
        [questionId]: value,
      },
    }));
  };

  const handleCheckbox = (isChecked, value, questionId, categoryKey) => {
    setAnswers(prev => {
      const currentAnswers = prev[categoryKey][questionId] || [];
      const updatedAnswers = isChecked
        ? [...currentAnswers, value]
        : currentAnswers.filter(ans => ans !== value);

      return {
        ...prev,
        [categoryKey]: {
          ...prev[categoryKey],
          [questionId]: updatedAnswers,
        },
      };
    });
  };

  const handleExCheckbox = e => {
    const { value, checked } = e.target;
    if (checked) {
      // 다른 값은 배열에 추가
      setAnswerEx(prev => [...prev.filter(item => item !== "e"), value]);
    } else {
      // 체크 해제 시 배열에서 제거
      setAnswerEx(prev => prev.filter(item => item !== value));
    }
  };

  const surveyStart = () => {
    if (!name) return alert("이름을 입력해 주세요");
    if (!mbti)
      return alert(
        "MBTI를 골라주세요. MBTI를 모르면 '잘 모르겠어요'를 선택해 주세요"
      );
    if (!agree) return alert("개인정보 수집에 동의하셔야 진행이 가능합니다");

    setProfile({ name, gender, mbti, photo, photoTask, phone, agree });
    setCategory(1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const surveySubmit = async () => {
    const totalQuestions = surveyA.length + surveyB.length + surveyC.length;
    const totalAnswers =
      Object.keys(answers.s01).length +
      Object.keys(answers.s02).length +
      Object.keys(answers.s03).length;

    if (totalQuestions > totalAnswers)
      return alert("답변하지 않은 문항이 있습니다.");

    const now = dayjs().format("YYYY-MM-DD");
    const body = {
      surveyId: Number(no),
      profile,
      answers,
      answerEx,
      date: now,
      alias: aliasNum,
      created: serverTimestamp(),
    };
    if (
      window.confirm(`${profile.name}님의 설문지를 제출합니다. 진행할까요?`)
    ) {
      await setDoc(doc(collection(db, "renewalSurvey"), `${no}`), body);
      alert("제출이 완료되었습니다. 이용해주셔서 감사합니다.");
      navi("/complete?b=basic");
    }
  };

  return (
    <>
      {error && <Error />}
      {!loading ? (
        <div className="w-[98%] lg:container mx-auto mb-28 flex flex-col justify-center">
          <div className="bg-white p-3">
            설문지를 불러오고 있습니다. 잠시만 기다려 주세요...
          </div>
        </div>
      ) : (
        <div className="w-[98%] lg:container mx-auto mb-28">
          <h1 className="text-white text-3xl font-bold text-center my-2">
            KoTI 설문지 입니다{" "}
            <span className="block lg:inline">
              {category > 0 && `반갑습니다 ${profile.name}님`}
            </span>
          </h1>
          {category === 0 ? (
            <div className="bg-gray-100 text-lg w-[98%] lg:container border-2 mx-auto p-3 rounded mb-3 shadow-xl">
              <InputName name={name} setName={setName} />
              <InputPhone phone={phone} setPhone={setPhone} />
              <InputMbti mbti={mbti} setMbti={setMbti} />
              <Agree
                agree={agree}
                setAgree={setAgree}
                survey={surveyA.concat(surveyB, surveyC)}
                surveyStart={surveyStart}
              />
            </div>
          ) : (
            <>
              {category === 1 && (
                <>
                  <div
                    className="bg-white rounded drop-shadow-lg my-2"
                    data={surveyEx.id}
                  >
                    <div className="bg-gray-100 rounded-t p-4 grid-grid-cols-1">
                      <h2 className="text-2xl font-bold text-red-500">
                        {profile.name}님의 강점을 알려주세요!
                      </h2>
                      <div className="text-sm">({surveyEx.question})</div>
                    </div>
                    <div className="grid grid-cols-1 gap-y-4 text-xl p-2">
                      {surveyEx.answer.map((ans, idx) => (
                        <div
                          data={`강점-${idx + 1}`}
                          className="flex items-center gap-x-2 p-2 bg-gray-100 rounded-bl rounded-tr"
                          key={idx}
                        >
                          <input
                            id={`answerEx-${idx + 1}`}
                            type="checkbox"
                            value={ans}
                            name={`answerEx-${idx + 1}`}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
                            checked={answerEx.includes(ans)}
                            onChange={handleExCheckbox}
                          />
                          <label
                            htmlFor={`answerEx-${idx + 1}`}
                            className="text-lg lg:text-xl w-full"
                          >
                            {ans}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {category === 2 && (
                <>
                  <h2 className="bg-white p-4 my-2 text-2xl font-bold rounded">
                    1. {profile.name}님은 어떤 사람인가요?
                  </h2>
                  {surveyA.map((sur, idx) => (
                    <QuestionCard
                      key={idx}
                      survey={sur}
                      categoryKey="s01"
                      answers={answers}
                      handleAnswer={handleAnswer}
                      handleCheckbox={handleCheckbox}
                    />
                  ))}
                </>
              )}
              {category === 3 && (
                <>
                  <h2 className="bg-white p-4 my-2 text-2xl font-bold rounded">
                    2. {profile.name}님이 선호하는 회사
                  </h2>
                  {surveyB.map((sur, idx) => (
                    <QuestionCard
                      key={idx}
                      survey={sur}
                      categoryKey="s02"
                      answers={answers}
                      handleAnswer={handleAnswer}
                      handleCheckbox={handleCheckbox}
                    />
                  ))}
                </>
              )}
              {category === 4 && (
                <>
                  <h2 className="bg-white p-4 my-2 text-2xl font-bold rounded">
                    3. {profile.name}님에 대해 조금 더 알려주세요
                  </h2>
                  {surveyC.map((sur, idx) => (
                    <QuestionCard
                      key={idx}
                      survey={sur}
                      categoryKey="s03"
                      answers={answers}
                      handleAnswer={handleAnswer}
                      handleCheckbox={handleCheckbox}
                    />
                  ))}
                  <InputPhoto
                    photo={photo}
                    setPhoto={setPhoto}
                    name={name}
                    photoTask={photoTask}
                    setPhotoTask={setPhotoTask}
                  />
                </>
              )}
            </>
          )}
          {category > 0 && (
            <div className="fixed bottom-0 left-0 right-0 w-full p-2 mt-3 text-center bg-white border-t border-gray-300">
              {category > 1 && (
                <div className="mb-1">
                  현재까지 답변한 문항
                  <span className="font-bold text-lg text-indigo-600">
                    {Object.keys(answers.s01).length +
                      Object.keys(answers.s02).length +
                      Object.keys(answers.s03).length}{" "}
                    / {surveyA.length + surveyB.length + surveyC.length}
                  </span>
                </div>
              )}
              <button
                className="block w-[98%] lg:container mx-auto rounded p-2 text-white bg-indigo-500 hover:bg-indigo-700 text-xl hover:font-bold"
                onClick={() => {
                  if (category < 4) {
                    if (category === 1 && answerEx.length < 1) {
                      return alert("강점을 한 개 이상 체크해 주세요");
                    }
                    if (
                      category === 2 &&
                      Object.keys(answers.s01).length !== surveyA.length
                    )
                      return alert("모든 문항에 답변하신 후 진행해 주세요");
                    if (
                      category === 3 &&
                      Object.keys(answers.s02).length !== surveyB.length
                    )
                      return alert("모든 문항에 답변하신 후 진행해 주세요");
                    setCategory(category + 1);
                  } else {
                    surveySubmit();
                  }
                }}
              >
                {category < 4 ? "다음으로" : "저장하기"}
              </button>

              <button
                className="p-2 bg-gray-50 hidden"
                onClick={() => setCategory(category - 1)}
              >
                이전으로
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function QuestionCard({
  survey,
  categoryKey,
  answers,
  handleAnswer,
  handleCheckbox,
}) {
  return (
    <div
      className="rounded drop-shadow-lg my-2 overflow-y-hidden"
      data={survey.id}
    >
      <div className="text-lg bg-gray-100 p-2">
        {survey.question}{" "}
        {survey.type === "checkbox" && (
          <span className="text-red-500">(중복선택가능)</span>
        )}
      </div>
      {survey.answer.length > 1 ? (
        <div className="bg-white p-2 flex flex-col lg:flex-row flex-wrap gap-3">
          {survey.type === "radio"
            ? survey.answer.map((ans, idx) => (
                <div className="flex items-center gap-x-2 p-2" key={idx}>
                  <input
                    type="radio"
                    value={ans}
                    id={`${categoryKey}-${survey.id}-${idx}`}
                    name={`${categoryKey}-${survey.id}`}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
                    checked={answers[categoryKey][survey.id] === ans}
                    onChange={() => handleAnswer(ans, survey.id, categoryKey)}
                  />
                  <label
                    htmlFor={`${categoryKey}-${survey.id}-${idx}`}
                    className="text-lg w-full"
                  >
                    {ans}
                  </label>
                </div>
              ))
            : survey.answer.map((ans, idx) => (
                <div className="flex items-center gap-x-2 p-2" key={idx}>
                  <input
                    type="checkbox"
                    value={ans}
                    id={`${categoryKey}-${survey.id}-${idx}`}
                    name={`${categoryKey}-${survey.id}`}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
                    checked={(answers[categoryKey][survey.id] || []).includes(
                      ans
                    )}
                    onChange={e =>
                      handleCheckbox(
                        e.target.checked,
                        ans,
                        survey.id,
                        categoryKey
                      )
                    }
                  />
                  <label
                    htmlFor={`${categoryKey}-${survey.id}-${idx}`}
                    className="text-lg w-full"
                  >
                    {ans}
                  </label>
                </div>
              ))}
        </div>
      ) : (
        <div className="bg-white p-2">
          <input
            type="text"
            className="block bg-teal-50 w-full border p-2 mt-2"
            placeholder="주관식 문항입니다"
            value={answers[categoryKey][survey.id] || ""}
            onChange={e => handleAnswer(e.target.value, survey.id, categoryKey)}
          />
        </div>
      )}
    </div>
  );
}

export default BasicSurveyRenew;
