import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import greeting from "../../../Asset/newyear/greeting_m.webp";

function FullModalMobile(props) {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        data-aos="fade-in"
        data-aos-duration="1000"
        onClick={() => props.closeIt()}
        className="fixed z-30 bg-[#fefeea] bg-opacity-[90%] top-0 left-0 w-screen h-[100vh]"
      >
        <img
          src={greeting}
          className="w-full"
          alt="새해 복 많이 받으세요 다사다난했던 2024년을 마무리 하고, 2025년 새해에는 푸른 뱀의 기운을 받아, 멈추지 않는 채용서비스와 함께, 원하시는 바를 모두 이룰 수 있길 바랍니다"
        />
      </div>

      <div
        className="fixed z-20 bg-[#fefeea] bg-opacity-50 top-0 left-0 w-screen h-screen overflow-hidden"
        onClick={() => props.closeIt()}
      ></div>
    </>
  );
}

export default FullModalMobile;
