import React from "react";
const Test = () => {
  return (
    <>
      <div className="w-[360px] h-fit overflow-auto bg-white">
        <div className="w-fit h-[30px] flex flex-row flex-nowrap gap-x-2">
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
        </div>
        <div className="w-fit h-[30px] flex flex-row flex-nowrap gap-x-2">
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
          <div className="p-1 bg-blue-50">asdfasdfasdfasdf</div>
        </div>
      </div>
    </>
  );
};

export default Test;
